import { useEffect, useRef, useState } from 'react';
import AdyenCheckout from "@adyen/adyen-web";
import '@adyen/adyen-web/dist/adyen.css';
import ReDialog from './ReDialog';

const BrowserIframe = ({ splashState, setShowSplash, isLoading }) => {
    const getSearchParam = window.location.href.split('?')[1];
    const [domainFe] = useState(() => localStorage.getItem("domain_fe"))
    const iframeRef = useRef(null);
    const [localState, setLocalState] = useState({
        urlDomain: domainFe,
        path: localStorage.getItem("path") || '',
        showAdyen: false,
        dataSessionAdyen: '',
        loading: true,
        message: '',
        valid: true,
    });

    const updateLocalState = (newData) => {
        setLocalState((prev) => {
            const updatedState = { ...prev, ...newData };
            return updatedState;
        });
    };

    const getLocalState = (key) => {
        return key ? localState[key] : localState;
    };


    const handleCloseModal = () => {
        console.log('close adyen x -----');
        updateLocalState({
            showAdyen: false,
            valid: true
        });
        sendPostMessage2('close', true);
    };

    const sendPostMessage = (message, domain) => {
        iframeRef.current.addEventListener('load', () => {
            const data = {
                key: 'server',
                message: message
            }
            iframeRef.current.contentWindow.postMessage(data, domain);
        });
    };

    const sendPostMessage2 = (key, message) => {
        const data = {
            key: key,
            message: message
        }
        iframeRef.current.contentWindow.postMessage(data, getLocalState('urlDomain'));
    };

    const getPostMessage = (event) => {
        if (event.origin === getLocalState('urlDomain')) {
            if (event.data.key === "splashShown") {
                if (!isLoading) {
                    setShowSplash(false)
                }
            }
            if (event.data.show) {
                updateLocalState({
                    showAdyen: event.data.show,
                    dataSessionAdyen: event.data
                });
            } else {
                updateLocalState({
                    showAdyen: false
                });
            }
            if (event.data.key === "path") {
                localStorage.setItem("path", event.data.message)

                history.replaceState(null, '', event.data.message);
            }

        }
    };

    const createCheckoutSession = async (sessionAdyen) => {
        const session = {
            id: sessionAdyen.result.id, // Unique identifier for the payment session.
            sessionData: sessionAdyen.result.sessionData
        }

        const checkout = await AdyenCheckout({
            locale: sessionAdyen.result.shopperLocale,
            clientKey: sessionAdyen.result.key,
            environment: sessionAdyen.result.env,
            showPayButton: true,
            session,
            onPaymentCompleted: (response, _component) => {
                updateLocalState({
                    showAdyen: false
                });
                if (response) {
                    sendPostMessage2('adyen', response);
                }
            },
            onError: (error, _component) => {
                updateLocalState({
                    message: "Payment Failed, Please try again !",
                    valid: false
                });
            },
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: false,
                    holderNameRequired: false,
                    billingAddressRequired: false,
                    styles: {
                        error: {
                            color: 'red'
                        },
                        placeholder: {
                            color: '#d8d8d8'
                        },
                        validated: {
                            color: 'green'
                        }
                    }
                },
                storedCard: {
                    styles: {
                        base: {
                            background: 'red' // CSS color code for white
                        },
                    }
                }
            }
        });

        if (checkout.paymentMethodsResponse) {
            checkout.create('dropin').mount('#dropin-container');
        }
        updateLocalState({
            loading: false
        });
    }

    useEffect(() => {
        window.addEventListener('message', getPostMessage);

        return () => {
            window.removeEventListener('message', getPostMessage);
        };
    });

    useEffect(() => {
        if (Object.keys(splashState).length > 0 && splashState.server) {
            sendPostMessage(splashState.server, splashState.domain);
            if (getSearchParam) {
                updateLocalState({
                    urlDomain: splashState.domain,
                    path: splashState.path
                });
            }

            localStorage.setItem('searchParam', splashState.path)
        }

        //TODO: Mencegah error ketika reload di splash screen
    }, [splashState]);


    useEffect(() => {
        console.log('show adyen', getLocalState('showAdyen'));
        if (getLocalState('showAdyen')) {
            if (!getLocalState('dataSessionAdyen').error) {
                updateLocalState({
                    valid: true,
                });
                createCheckoutSession(getLocalState('dataSessionAdyen'));
            } else {
                updateLocalState({
                    message: getLocalState('dataSessionAdyen').message,
                    valid: false,
                    loading: false
                });
            }
        }
        // eslint-disable-next-line
    }, [getLocalState('showAdyen')]);

    return (
        <>
            {splashState && localState && (
                <iframe id='iframeFE' ref={iframeRef} title='Server'
                    src={
                        localState.urlDomain
                            ? localState.urlDomain + getLocalState('path')
                            : window.location.href
                    }
                    className='BrowserIframe' style={{ maxHeight: '100%', height: '100vh' }} />
            )}
            {getLocalState('showAdyen') &&
                // <div id='dropin-container'></div>
                <ReDialog
                    showModal={getLocalState('showAdyen')}
                    closeModal={() => handleCloseModal()}
                    loading={getLocalState('loading')}
                    valid={getLocalState('valid')}
                    message={getLocalState('message')}
                    sessionAdyen={getLocalState('dataSessionAdyen')}
                />
            }
        </>
    );
};

export default BrowserIframe;
